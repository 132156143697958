<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-0  pt-0">
              <h3 class="card-title font-weight-bold" style='font-size: 30px'>Artículos</h3>
              <div class='d-flex' style='gap: .4rem;'>
                <vs-button @click="$router.push('NuevoArticulo')">Crear Artículo</vs-button>
              </div>
            </div>
          </template>

          <div class='p-5'>

            <DataTableLotes ref="dataTableLotes" :fetch-data="fetchData" :columns="columns">
              <template v-slot:TotalVenta="{ row }">
                {{ formatearNumero(row.TotalVenta) }}
              </template>
              <template v-slot:Id="{ row }">
                <div class="d-flex justify-content-center" style="gap: .5rem;">
                  <vs-button v-b-tooltip.hover title='ver' success icon class=' '>
                    <i class="fa-solid fa-eye"></i>
                  </vs-button>
                  <vs-button v-b-tooltip.hover title='Editar' icon
                             @click="$router.push(`EditarArticulo/${row.Id}`)">
                    <i class="fa-solid fa-pen"></i>
                  </vs-button>
                  <vs-button v-b-tooltip.hover title='Eliminar' danger icon class=' '
                             @click='deleteArticulo(row.Id)'>
                    <i class="fa-solid fa-trash"></i>
                  </vs-button>
                </div>
              </template>
            </DataTableLotes>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Swal from 'sweetalert2';
import DataTableLotes from "@/components/Datatable/DataTableLotes.vue";


export default {
  components: {DataTableLotes},
  data: () => ({
    article: [],
    EmpresaId: null,
    columns: [
      {label: "Sku", key: "Sku", sortable: true, width: '100px'},
      {label: "Nombre", key: "Nombre", sortable: true, width: '100px'},
      {label: "Descripción", key: "Descripcion", sortable: true, width: '150px'},
      {label: "Tipo", key: "Tipo", sortable: true, width: '60px'},
      {label: "Categoría", key: "Categoria", sortable: true, width: '120px'},
      {label: "Stock", key: "Stock", sortable: true, width: '60px'},
      {label: "Precios de Venta", key: "PrecioVenta", sortable: true, width: '120px'},
      {label: "Precios de Compra", key: "PrecioCompra", sortable: true, width: '120px'},
      {label: "Opciones", key: "Id", sortable: false, width: '180px'}
    ]
  }),
  async created() {
    const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
    this.EmpresaId = valor.empresa.id;
  },
  methods: {
    async fetchData({filtro, orden, cantDatos, paginacion}) {
      if (!this.EmpresaId) {
        console.error("EmpresaId no está definido.");
        return {TotalDatos: 0, data: []};
      }
      let params = {
        filtro: filtro,
        orden: orden,
        cantDatos: cantDatos,
        paginacion: paginacion
      };
      let data = [];
      let total = 0;

      await Promise.all([
        this.$store.dispatch('hl_get', {
          path: `CONTArticulo/ObtenerCantidadArticulosId/${this.EmpresaId}?filtro=${params.filtro}`
        }),
        this.$store.dispatch('hl_post', {
          path: 'CONTArticulo/ObtenerArticuloEmpresaId/' + this.EmpresaId, data: params
        })
      ]).then(([cantidadDatos, Datos]) => {
        if (cantidadDatos.respuesta) {
          total = cantidadDatos.data;
        }
        if (Datos.respuesta) {
          data = Datos.data;
        }
      });
      return {total, data};
    },
    async deleteArticulo(id) {
      await Swal.fire({
        title: '¿Estás seguro que deseas eliminar el Articulo?',
        text: "¡No podrás revertir esta acción!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, borrarlo!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("hl_delete", {
              path: `CONTArticulo/EliminarArticulo/${id}`,
            }).then(response => {
              if (response.respuesta) {
                Swal.fire("Listo!", "Articulo eliminado Correctamente", "success");
                this.$refs.dataTableLotes.$emit("actualizar");
              } else {
                Swal.fire({
                  title: 'Error',
                  text: response.mensaje,
                  icon: 'error',
                  confirmButtonText: 'Aceptar'
                })
              }
            })

          } catch (error) {
            await Swal.fire("Error", "Hubo un problema al eliminar el Articulo: "+error, "error");
          }
        }
      });
    },
  },
  watch: {
    search() {
      this.getData();
    },
    max() {
      this.getData();
    },
    page() {
      this.getData();
    },
    columna() {
      this.getData();
    }
  }
}
</script>

<style>
.vs-input {
  width: 100%;
}

.header-title {
  width: 100% !important;
}
</style>
